#portfolio {
  min-height: calc(100vh - 80px);
  padding-top: 40px;
}

.project-container {
  position: relative;
}

.port {
  padding: 30px;
  font-size: 60px;
  margin-left: 15vw;
  background: #303c48;
  border-radius: 5px;
  display: inline-block;
  transform: skew(-15deg);
}
.port p {
  transform: skew(15deg);
  margin: -20px 0 -20px 0;
}
.icons > * {
  color: #485564;
  padding: 5px;
}

.icons {
  margin-top: -20px;
  text-align: center;
}

.visit {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  align-items: baseline;
  position: absolute;
  bottom: -5px;
  width: 100%;
}

.visit > * {
  color: #485564;
  font-size: 30px;
  padding: 0;
  margin: 20px;
  outline: none;
}

.visit a:hover {
  text-decoration: none;
  color: #485564d5;
}

.visit i:hover {
  color: #485564d5;
}
.visit a > * {
  color: #485564;
}

.project-title {
  text-align: center;
  padding: 10px;
  font-size: 20px;
}

.project-description {
  font-size: 12px;
  text-align: center;
  padding: 10px;
}

.projects {
  display: grid;
  padding-top: 5vh;
  margin: 0 auto;
  max-height: 50vh;
  max-width: 50vw;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items: center;
  color: #485564;
}

.projects > * {
  height: 15rem;
  margin: 1rem;
  width: 15rem;
  background: white;
  border-radius: 3px;
}
