a {
  padding:20px;
}

i{
  color:white;
}

.hub:hover{
  color:#ffffffc0;
}