.try {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
      height:100vh;
      background-color: #485564;
    
}
html{
    background-color: #485564;
}

.loading{
    text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.header{
    display:grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    border-bottom: 2px solid gray;
    background:white;
    box-shadow: 10px 5px 5px black
}

.header p{
    font-size: 25px;
    font-weight: 100;
    padding-top:15px;
    
}
form{
    
}
.addform{
    background: rgb(236, 236, 236);
    max-width: 50vw;
    border:2px solid gray;

}
.checkbox{
    margin:0;
    padding:0;
    padding-left:20px;
}
.addform > *{
    padding:20px;
}
.addbook{
    margin:20px;
}
.back-arrow{
    font-size: 30px;
    padding:20px 10px 10px 10px;
}
.library{
    display: grid;
    margin: 200px auto;
    max-width: 50vw;
    border:1px solid black;
    border-radius: 5px;
    grid-template-columns: 1fr; 
    background: white;
    box-shadow: 10px 10px 5px rgba(0, 0, 0);
}
.example-book > *{
    margin:0;
    border-right: 1px solid rgba(206, 202, 202, 0.363);
    padding-right:8px;
}
.example-book button{
    margin-right:8px;
}
.example-book{
    display:grid;
    grid-template-columns: 5fr 3fr 1fr 1fr 1fr;
    padding:10px 0 10px 10px;
    grid-gap:10px;
    align-items: center;
    border-bottom: 1px solid rgba(206, 202, 202, 0.363);
    
}
.top{
    grid-column: 1 / -1;
    background: rgba(206, 202, 202, 0.199);
    border-bottom:1px solid rgba(206, 202, 202, 0.363);
    padding:5px;
    display: grid;
    grid-auto-flow: column;
    grid-gap:30px;
}
.alert{
    text-align: center;
    display: none;
}
.example-book:nth-child(odd){
    background:rgba(206, 202, 202, 0.199);
    
}
@media (max-width: 1200px){
    .library{
        max-width: 90vw;
        margin:30px auto;
    }
}