.hero {
  font-size: 80px;
  text-align: center;
  padding-top: 30vh;
}
.main {
  min-height: 100vh;
  position: relative;
}

.skills > * {
  padding: 8px;
  font-size: 40px;
}
.skills {
  margin-top: -20px;
}

.email {
  font-size: 20px;
}

.down-arrow {
  color: white;
  background: inherit;
  border: none;
  cursor: pointer;
}
.footer-component-down {
  position: absolute;
  text-align: center;
  padding: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}
@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
.footer-component-down button:hover {
  color: #ffffffc0;
}
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}
.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}
