.hero {
  font-size: 80px;
  text-align: center;
  padding-top: 30vh;
}
.main {
  min-height: 100vh;
  position: relative;
}

.skills > * {
  padding: 8px;
  font-size: 40px;
}
.skills {
  margin-top: -20px;
}

.email {
  font-size: 20px;
}

.down-arrow {
  color: white;
  background: inherit;
  border: none;
  cursor: pointer;
}
.footer-component-down {
  position: absolute;
  text-align: center;
  padding: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}
@keyframes bounce {
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}
.footer-component-down button:hover {
  color: #ffffffc0;
}
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}
.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}

a {
  padding: 20px; }

i {
  color: white; }

.hub:hover {
  color: #ffffffc0; }

.header-component{
  top:0;
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 1fr 20fr;
  grid-gap:10px;
  padding:15px;
  justify-items: center;

}
.header-component > *{
  font-size: 20px;
  cursor: pointer;
}

.header-component > *:hover{
  color:#ffffffc0;
}

.header-component > *::after {
  content: '';
  display: block;
  width:0;
  height: 2px;
  background:#ffffffc0;
  transition: width .5s;
}

.header-component > *:hover::after {
  width:100%;
  color:#ffffffc0;
}


.footer-component button {
  color: white;
  background: inherit;
  border: none;
  cursor: pointer;
}
.footer-component {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 10px;
}

.to-top {
  font-size: 12px;
  cursor: none;
}

.footer-component button:hover {
  color: #ffffffc0;
}

.bounce {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}

#about {
  min-height: 100vh;
  padding-top: 40px;
}
.about-me {
  padding: 30px;
}
.about-content p {
  padding: 10px;
}
.about-subdiv {
  min-height: 100vh;
  margin-top: -40px;
}

#about .about-me {
  font-size: 60px;
  margin: -20px 0 -20px 0;
  -webkit-transform: skew(15deg);
          transform: skew(15deg);
}

.skills-list {
  margin: 0 50px 0 50px;
  display: grid;
  grid-gap: 10px;
  justify-items: start;
  grid-template-columns: 1fr;
  font-size: 30px;
  list-style-type: none;
}

.about-container {
  margin-top: 50px;
  background: #303c48;
  border-radius: 5px;
  display: inline-block;
  -webkit-transform: skew(-15deg);
          transform: skew(-15deg);
  margin-left: 13vw;
}

.about-content {
  padding-top: 5vh;
  margin: 0 20vw 0 20vw;
}
.footer-component-about {
  text-align: center;
  padding: 10px;
  margin-top: 6vh;
  margin-left: auto;
  margin-right: auto;
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}
@keyframes bounce {
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}
.footer-component-about button:hover {
  color: #ffffffc0;
}

.home{
  background-color: #485564;
  color:white;
}
#portfolio {
  min-height: calc(100vh - 80px);
  padding-top: 40px;
}

.project-container {
  position: relative;
}

.port {
  padding: 30px;
  font-size: 60px;
  margin-left: 15vw;
  background: #303c48;
  border-radius: 5px;
  display: inline-block;
  -webkit-transform: skew(-15deg);
          transform: skew(-15deg);
}
.port p {
  -webkit-transform: skew(15deg);
          transform: skew(15deg);
  margin: -20px 0 -20px 0;
}
.icons > * {
  color: #485564;
  padding: 5px;
}

.icons {
  margin-top: -20px;
  text-align: center;
}

.visit {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  align-items: baseline;
  position: absolute;
  bottom: -5px;
  width: 100%;
}

.visit > * {
  color: #485564;
  font-size: 30px;
  padding: 0;
  margin: 20px;
  outline: none;
}

.visit a:hover {
  text-decoration: none;
  color: #485564d5;
}

.visit i:hover {
  color: #485564d5;
}
.visit a > * {
  color: #485564;
}

.project-title {
  text-align: center;
  padding: 10px;
  font-size: 20px;
}

.project-description {
  font-size: 12px;
  text-align: center;
  padding: 10px;
}

.projects {
  display: grid;
  padding-top: 5vh;
  margin: 0 auto;
  max-height: 50vh;
  max-width: 50vw;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items: center;
  color: #485564;
}

.projects > * {
  height: 15rem;
  margin: 1rem;
  width: 15rem;
  background: white;
  border-radius: 3px;
}

.try {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
      height:100vh;
      background-color: #485564;
    
}
html{
    background-color: #485564;
}

.loading{
    text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.header{
    display:grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    border-bottom: 2px solid gray;
    background:white;
    box-shadow: 10px 5px 5px black
}

.header p{
    font-size: 25px;
    font-weight: 100;
    padding-top:15px;
    
}
form{
    
}
.addform{
    background: rgb(236, 236, 236);
    max-width: 50vw;
    border:2px solid gray;

}
.checkbox{
    margin:0;
    padding:0;
    padding-left:20px;
}
.addform > *{
    padding:20px;
}
.addbook{
    margin:20px;
}
.back-arrow{
    font-size: 30px;
    padding:20px 10px 10px 10px;
}
.library{
    display: grid;
    margin: 200px auto;
    max-width: 50vw;
    border:1px solid black;
    border-radius: 5px;
    grid-template-columns: 1fr; 
    background: white;
    box-shadow: 10px 10px 5px rgba(0, 0, 0);
}
.example-book > *{
    margin:0;
    border-right: 1px solid rgba(206, 202, 202, 0.363);
    padding-right:8px;
}
.example-book button{
    margin-right:8px;
}
.example-book{
    display:grid;
    grid-template-columns: 5fr 3fr 1fr 1fr 1fr;
    padding:10px 0 10px 10px;
    grid-gap:10px;
    align-items: center;
    border-bottom: 1px solid rgba(206, 202, 202, 0.363);
    
}
.top{
    grid-column: 1 / -1;
    background: rgba(206, 202, 202, 0.199);
    border-bottom:1px solid rgba(206, 202, 202, 0.363);
    padding:5px;
    display: grid;
    grid-auto-flow: column;
    grid-gap:30px;
}
.alert{
    text-align: center;
    display: none;
}
.example-book:nth-child(odd){
    background:rgba(206, 202, 202, 0.199);
    
}
@media (max-width: 1200px){
    .library{
        max-width: 90vw;
        margin:30px auto;
    }
}
.Board {
  display: grid;
  grid-template-columns: repeat(40, 1fr);
  position: relative;
  margin: 0 auto;
  background-color: #000;
  background-image: linear-gradient(#333 1px, transparent 1px),
    linear-gradient(90deg, #333 1px, transparent 1px);
}
.page {
  height: 100%;
  width: 100%;
  padding-top: 20px;
  background: rgb(238, 219, 174);
  background: radial-gradient(
    circle,
    rgba(238, 219, 174, 1) 58%,
    rgba(233, 182, 148, 1) 80%
  );
}

.runbutton {
  padding: 20px;
  text-align: center;
}

.runbtn {
  height: 60px;
  width: 150px;
  border: black;
  border-radius: 5px;
  background-color: rgb(165, 66, 66);
  color: white;
}

.runbtn:hover {
  border: black 1px solid;
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container{
  max-width: 100%;
  width:100vw;
  height:100vh;
  z-index: -1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.app{
  width:50vw;
  margin:0 auto;
  padding-top:300px;
  display: grid;
  grid-gap:5px;
}
.inputField{
  grid-column: 1 / -1;
  height:50px;
  display:grid;
  grid-template-columns: 1fr;
  grid-gap:5px;
  margin-bottom:20px;
}
.inputField >*{
}
.app input{
  height: 50px;
  border-radius: 40px;
  text-align:center;
}
.app button{
  border-radius: 40px;
}
.weather{
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  justify-items:center;
  font-size: 20px;
  background:white;
  border-radius: 10px;
}
.name{
  justify-self:start;
  padding-left:20px;
}
