.Board {
  display: grid;
  grid-template-columns: repeat(40, 1fr);
  position: relative;
  margin: 0 auto;
  background-color: #000;
  background-image: linear-gradient(#333 1px, transparent 1px),
    linear-gradient(90deg, #333 1px, transparent 1px);
}
.page {
  height: 100%;
  width: 100%;
  padding-top: 20px;
  background: rgb(238, 219, 174);
  background: radial-gradient(
    circle,
    rgba(238, 219, 174, 1) 58%,
    rgba(233, 182, 148, 1) 80%
  );
}

.runbutton {
  padding: 20px;
  text-align: center;
}

.runbtn {
  height: 60px;
  width: 150px;
  border: black;
  border-radius: 5px;
  background-color: rgb(165, 66, 66);
  color: white;
}

.runbtn:hover {
  border: black 1px solid;
  cursor: pointer;
}
