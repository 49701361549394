.header-component{
  top:0;
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 1fr 20fr;
  grid-gap:10px;
  padding:15px;
  justify-items: center;

}
.header-component > *{
  font-size: 20px;
  cursor: pointer;
}

.header-component > *:hover{
  color:#ffffffc0;
}

.header-component > *::after {
  content: '';
  display: block;
  width:0;
  height: 2px;
  background:#ffffffc0;
  transition: width .5s;
}

.header-component > *:hover::after {
  width:100%;
  color:#ffffffc0;
}

