#about {
  min-height: 100vh;
  padding-top: 40px;
}
.about-me {
  padding: 30px;
}
.about-content p {
  padding: 10px;
}
.about-subdiv {
  min-height: 100vh;
  margin-top: -40px;
}

#about .about-me {
  font-size: 60px;
  margin: -20px 0 -20px 0;
  transform: skew(15deg);
}

.skills-list {
  margin: 0 50px 0 50px;
  display: grid;
  grid-gap: 10px;
  justify-items: start;
  grid-template-columns: 1fr;
  font-size: 30px;
  list-style-type: none;
}

.about-container {
  margin-top: 50px;
  background: #303c48;
  border-radius: 5px;
  display: inline-block;
  transform: skew(-15deg);
  margin-left: 13vw;
}

.about-content {
  padding-top: 5vh;
  margin: 0 20vw 0 20vw;
}
.footer-component-about {
  text-align: center;
  padding: 10px;
  margin-top: 6vh;
  margin-left: auto;
  margin-right: auto;
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}
@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
.footer-component-about button:hover {
  color: #ffffffc0;
}
