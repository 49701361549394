.container{
  max-width: 100%;
  width:100vw;
  height:100vh;
  z-index: -1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.app{
  width:50vw;
  margin:0 auto;
  padding-top:300px;
  display: grid;
  grid-gap:5px;
}
.inputField{
  grid-column: 1 / -1;
  height:50px;
  display:grid;
  grid-template-columns: 1fr;
  grid-gap:5px;
  margin-bottom:20px;
}
.inputField >*{
}
.app input{
  height: 50px;
  border-radius: 40px;
  text-align:center;
}
.app button{
  border-radius: 40px;
}
.weather{
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  justify-items:center;
  font-size: 20px;
  background:white;
  border-radius: 10px;
}
.name{
  justify-self:start;
  padding-left:20px;
}